import React, {FC, useState} from "react";
import {RoundIcon} from "./RoundIcon";
import {ReviewModal} from "./ReviewModal";

type SentMessageProps = {
    messageId?: string,
    human: boolean,
    imageSrc: string,
    text: string
    proposals?: string[]
    userId?: string
};

export const SentMessage: FC<SentMessageProps> = ({messageId, text, imageSrc, human, proposals,userId}) => {
    const [showModal, setShowModal] = useState(false)
    return <div
        className={`flex ${human || "flex-row-reverse"} justify-end mb-4 items-center`}>
        <div
            className={`${human ? "mr-2 bg-blue-400 rounded-tl-3xl rounded-tr-xl rounded-bl-3xl" : "ml-2 bg-gray-400 rounded-tr-3xl rounded-tl-xl rounded-br-3xl"} py-3 px-4 text-white`}
        >
            {text}
            {!human && messageId && <div>
                <button onClick={(() => setShowModal(true))}
                        className="bg-fuchsia-500 hover:bg-fuchsia-700 text-white px-2 font-bold rounded">
                    review
                </button>
                {showModal && <ReviewModal onClose={() => setShowModal(false)} messageId={messageId} userId={userId}/>}
            </div>}
            {
                proposals && <>
                    <label className="text-sm text-slate-950">Propositions:</label>
                    <ul>
                        {proposals.map((el, id) => <li className="text-xs text-blue-900" key={id}>{el}</li>)}
                    </ul>
                </>
            }
        </div>
        <RoundIcon src={imageSrc}/>
    </div>;
};
