import {MessageInput} from "./MessageInput";
import React, {FC} from "react";
import {Message} from "../types/Message";
import {SentMessage} from "./SentMessage";

type MessageBoxProps = {
    // conversationId: string
    onMessageSent: (newMessage: string) => void,
    avatarImg: string,
    messages?: Message[]
    userId?: string
}

export const MessageBox: FC<MessageBoxProps> = ({onMessageSent, messages, avatarImg,userId}) => {
    return <div className="w-full px-5 flex flex-col-reverse h-full justify-between  overflow-y-auto">
        <MessageInput onMessageSent={onMessageSent}/>
        <div className="flex flex-col mt-5 justify-end">
            {messages?.map(
                (el, index) => <SentMessage messageId={el.messageId}
                                            text={el.text}
                                            imageSrc={el.sender === "avatar" ? avatarImg : "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg"}
                                            human={el.sender === "human"}
                                            key={index}
                                            proposals={el.propositions} userId={userId}/>
            )}
        </div>
    </div>;
};
