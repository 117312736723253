import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import './App.css';
import {Layout} from "./components/Layout";
import {LeftPanel} from "./components/LeftPanel";
import {Conversation} from "./components/Conversation";
import {RoundIcon} from "./components/RoundIcon";
import {MessageBox} from "./components/MessageBox";
import {Message} from "./types/Message";
import {URL_BASE} from "./urls";
import {User} from "./types/User";
import {CreateUser} from "./components/CreateUser";
import {ConversationType} from "./types/ConversationType";
import {Avatar} from "./types/Avatar";
import {SelectUser} from "./components/SelectUser";

interface LanguageButtonProps {
    lang: 'pl' | 'en';
    setLang: Dispatch<SetStateAction<'pl' | 'en'>>;
    currentLang: 'pl' | 'en';
    flag: string;
    ariaLabel: string;
}

interface InputFieldProps {
    label: string;
    type: string;
    value: number;
    min: number;
    max: number;
    step: number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface UserAvatar {
    avatarId: string;
    img: string;
    avatarName: string;
}

interface AvatarListProps {
    avatars: UserAvatar[];
    startConversation: (avatarId: string) => void;
}

// LanguageButton Component
const LanguageButton: React.FC<LanguageButtonProps> = ({lang, setLang, currentLang, flag, ariaLabel}) => (
    <span
        onClick={() => setLang(lang)}
        className={`cursor-pointer  px-2 py-1 ${currentLang === lang ? 'bg-blue-500 text-white rounded' : ''}`}
        role="img"
        aria-label={ariaLabel}
    >
    {flag}
  </span>
);

// InputField Component
const InputField: React.FC<InputFieldProps> = ({label, type, value, min, max, step, onChange}) => (
    <div className="w-full">
        <label className="text-xs">{label}</label>
        <input
            type={type}
            className="border p-2 rounded-md w-full"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={onChange}
        />
    </div>
);

const CheckBoxInput: React.FC<{
    label: string,
    checked: boolean,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({label, onChange, checked}) => (
    <div className="flex justify-center items-center w-full pt-3">
        <label htmlFor="myCheckbox" className="text-sm flex items-center">
            <input type="checkbox" id="myCheckbox"
                   className="mr-2 border p-2 rounded-md"
                   onChange={onChange}
                   checked={checked}/> {label}
        </label>
    </div>
);


// AvatarList Component
const AvatarList: React.FC<AvatarListProps> = ({avatars, startConversation}) => (
    <div className="flex justify-around py-5">
        {avatars.map((avatar, index) => (
            <RoundIcon
                key={index}
                onClick={() => startConversation(avatar.avatarId)}
                src={avatar.img}
                title={avatar.avatarName}
                size="lg"
            />
        ))}
    </div>
);
const App = () => {
        const [lang, setLang] = useState<"pl" | "en">("pl")
        const [selectedUser, setSelectedUser] = useState<string>()
        const [users, setUsers] = useState<User[]>([])
        const [conversations, setConversations] = useState<ConversationType[]>([])
        const [avatars, setAvatars] = useState<Avatar[]>([])
        const [selectedConversation, setSelectedConversation] = useState<string>()
        const currentConv = conversations.find((el) => el.id === selectedConversation);
        const currentAvatarImg = avatars.find(a => currentConv && a.avatarId === currentConv.avatarId)?.img;
        const [threshold, setThreshold] = useState(0.87)
        const [messageContinuationLimit, setMessageContinuationLimit] = useState<number>(2)
        const [disableNER, toggleDisableNer] = useState<boolean>(false)
        useEffect(() => {
            fetch(URL_BASE + `api/front/users/`).then(res => res.json())
                .then((data: { users: { userId: string, username: string }[] }) => {
                    setUsers(data.users)
                }).catch(err => console.error(err))
        }, []);

        const startConversation = useCallback((avatarId: string) => {
            if (selectedUser) {
                fetch(URL_BASE + `api/front/${selectedUser}/conversations/`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        languageISO: lang,
                        avatarId: avatarId,
                        threshold: threshold,
                        messageInspectionLimit: messageContinuationLimit,
                        disableNER: disableNER
                    })
                })
                    .then(res => res.json())
                    .then((data: {
                        message: { messageId: string, conversationId: string, text: string, timestamp: string },
                        propositions?: string[]
                    }) => {
                        setConversations(prevState => [{
                            id: `${data.message.conversationId}`,
                            messages: [{
                                sender: "avatar",
                                text: data.message.text,
                                messageId: data.message.messageId,
                                propositions: data.propositions
                            }],
                            avatarId: `${avatarId}`,
                            lastUpdatedAt: `${data.message.timestamp}`
                        }, ...prevState])
                        setSelectedConversation(`${data.message.conversationId}`)
                    }).catch(console.error)
            }
        }, [lang, selectedUser, threshold, messageContinuationLimit, disableNER]);

        const sendMessage = useCallback((newMessage: string) => {
            if (selectedConversation) {
                fetch(`${URL_BASE}api/v2/conversations/${selectedConversation}/messages/`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({text: newMessage})
                }).then(res => res.json())
                    .then((data: { message: Message, propositions: string[] }) => {
                        setConversations(prevState => prevState.map(conv => {
                            if (conv.id !== selectedConversation) {
                                return conv
                            } else {
                                const newMessage: Message = {...data.message, propositions: data.propositions};
                                return {
                                    ...conv,
                                    messages: conv.messages ? [...conv.messages, newMessage] : [newMessage]
                                }
                            }
                        }))
                    }).catch(console.error)
            }
        }, [selectedConversation]);

        useEffect(() => {
            if (selectedUser) {
                fetch(URL_BASE + `api/front/${selectedUser}/conversations/`)
                    .then(res => res.json())
                    .then((data: {
                        conversations: {
                            conversationId: string,
                            conversationName: string,
                            avatarId: string,
                            lastUpdatedAt: string
                        }[]
                    }) => {
                        const newConversations = data.conversations
                            .map(el => ({
                                id: `${el.conversationId}`,
                                name: `${el.conversationName || el.conversationId}`,
                                messages: [],
                                avatarId: `${el.avatarId}`,
                                lastUpdatedAt: `${el.lastUpdatedAt}`
                            }))
                        setConversations(newConversations)
                        setSelectedConversation(newConversations.length > 0 ? newConversations[0].id : undefined)
                    }).catch(err => console.error(err))
            }
        }, [selectedUser]);

        useEffect(() => {
            if (selectedConversation && selectedUser) {
                fetch(URL_BASE + `api/front/${selectedUser}/conversations/${selectedConversation}/`)
                    .then(res => res.json())
                    .then((data: { messages: Message[] }) => {
                        setConversations(prevState => prevState.map(el => {
                            if (el.id !== selectedConversation)
                                return el
                            return {
                                ...el, messages: data.messages.map(mEl => {
                                    if (el.messages) {
                                        const found = el.messages.find(v => v.messageId === mEl.messageId);
                                        if (found)
                                            return {...mEl, propositions: found.propositions}
                                    }
                                    return mEl
                                })
                            }
                        }))
                    })
                    .catch(err => console.error(err))
            }
        }, [selectedConversation, selectedUser]);

        useEffect(() => {
            fetch(URL_BASE + 'api/front/avatars/')
                .then(res => res.json())
                .then((data: {
                    avatars: { avatarName: string, avatarId: string, img?: string, languagesSpoken: string[] }[]
                }) => {
                    const newData = data.avatars
                        .map(el => ({
                            avatarName: `${el.avatarName}`.toLocaleUpperCase(),
                            avatarId: `${el.avatarId}`,
                            img: el.img || "",
                            languagesSpoken: el.languagesSpoken || []
                        }))
                    setAvatars(newData)
                })
                .catch(err => console.error(err))
        }, [])

        return (
            <Layout loginDiv={
                <div className="flex flex-row p-2 justify-center items-center">
                    <SelectUser users={users} selectedUser={selectedUser} setSelectedUser={(el) => {
                        setSelectedUser(el)
                        setSelectedConversation(undefined)
                    }}/>
                    <CreateUser setUsers={setUsers}/>
                </div>
            }>
                <div className="flex flex-row justify-between bg-white h-[90vh]">
                    <LeftPanel>
                        <div className="border-b-4">
                            <div className="mt-4 w-full text-center">
                                <div className="py-2 text-xs">Select conversation parameters, then click on the avatar to start conversation</div>
                                <div className="py-2">
                                    <LanguageButton lang="pl" setLang={setLang} currentLang={lang} flag="🇵🇱"
                                                    ariaLabel="Polish Flag"/>
                                    <LanguageButton lang="en" setLang={setLang} currentLang={lang} flag="🇬🇧"
                                                    ariaLabel="English Flag"/>
                                </div>
                            </div>
                            <div className="flex-col">
                                <div className="flex w-full">
                                    <InputField
                                        label="Similarity threshold"
                                        type="number"
                                        value={threshold}
                                        min={0}
                                        max={1}
                                        step={0.01}
                                        onChange={(e) => setThreshold(parseFloat(e.target.value) || threshold)}
                                    />
                                    <InputField
                                        label="cont. limit"
                                        type="number"
                                        value={messageContinuationLimit}
                                        min={1}
                                        max={1000}
                                        step={1}
                                        onChange={(e) => setMessageContinuationLimit(parseInt(e.target.value, 10) || messageContinuationLimit)}
                                    />
                                </div>
                                <CheckBoxInput
                                    label="Disable NER"
                                    checked={disableNER}
                                    onChange={(e) => {
                                        toggleDisableNer(e.target.checked)
                                    }}
                                />
                                <AvatarList avatars={avatars} startConversation={startConversation}/>
                            </div>
                        </div>
                        <div>
                            {
                                conversations.sort((el1, el2) => el1.lastUpdatedAt < el2.lastUpdatedAt ? 1 : -1).map((el, index) => {
                                    return <Conversation onClick={() => setSelectedConversation(el.id)}
                                                         imageSrc={avatars.find(a => el && a.avatarId === el.avatarId)?.img}
                                                         name={el.name || el.id} key={index}
                                                         selected={el.id === selectedConversation}/>
                                })
                            }
                        </div>

                    </LeftPanel>

                    <MessageBox avatarImg={currentConv ? currentAvatarImg || "" : ""}
                                onMessageSent={(newMessage: string) => {
                                    setConversations(prevState => prevState.map(el => {
                                        if (el.id === selectedConversation) {
                                            return {
                                                ...el,
                                                messages: el.messages ? [...el.messages, {
                                                    text: newMessage,
                                                    sender: 'human',
                                                    messageId: undefined
                                                }] : [{
                                                    text: newMessage,
                                                    sender: 'human',
                                                    messageId: undefined
                                                }]
                                            }
                                        } else {
                                            return el
                                        }
                                    }))
                                    sendMessage(newMessage)
                                }} messages={currentConv?.messages} userId={selectedUser}/>
                </div>
            </Layout>
        );
    }
;
export default App;
