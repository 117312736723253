import React, {FC} from "react";
import {RoundIcon} from "./RoundIcon";

type ConversationProps = {
    imageSrc?: string,
    onClick: () => void,
    name: string,
    selected?: boolean
};
export const Conversation: FC<ConversationProps> = ({imageSrc, onClick, name, selected}) =>
    <div className={`flex flex-row py-4 px-2 items-center border-b-2 ${selected ? 'bg-blue-400' : 'hover:bg-blue-200'}`} onClick={onClick}>
        {imageSrc && <div className="w-1/4"><RoundIcon src={imageSrc} size={"md"}/></div>}
        <div className="w-full">
            <div className="text-lg font-semibold">{name}</div>

        </div>
    </div>;