import React from "react";
import {User} from "../types/User";

interface SelectUserProps {
    users: User[];
    selectedUser?: string;
    setSelectedUser: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const SelectUser: React.FC<SelectUserProps> = ({
                                                          users,
                                                          selectedUser,
                                                          setSelectedUser,
                                                      }) => {
    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedUser(event.target.value);
    };

    return (
        <div className="my-4 mx-2">
            <label htmlFor="userSelect" className="mr-2">
                Select User:
            </label>
            <select
                id="userSelect"
                value={selectedUser}
                onChange={handleUserChange}
                className="border p-2 rounded-md"
            >
                <option value="" disabled>
                    Select a user
                </option>
                {users.map((user) => (
                    <option key={user.userId} value={user.userId}>
                        {user.username}
                    </option>
                ))}
            </select>
        </div>
    );
};