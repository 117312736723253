import React, {FC} from "react";

type RoundIconProps = { src: string, title?: string, size?: "sm" | "md" | "lg", onClick?: () => void };
export const RoundIcon: FC<RoundIconProps> = ({
                                                  src,
                                                  title,
                                                  size = "sm",
                                                  onClick,
                                              }) => {
    const imageSize = {
        sm: "h-8 w-8",
        md: "h-12 w-12",
        lg: "h-14 w-14",
    }[size];

    return (
        <img
            onClick={onClick}
            src={src}
            className={`object-cover rounded-full shrink-0 grow-0 ${onClick && "cursor-pointer"} ${imageSize}`}
            alt={title || ""}
            title={title || ""}
        />
    );
};