import React, {useState} from "react";
import {User} from "../types/User";
import {URL_BASE} from "../urls";

export const CreateUser: React.FC<{ setUsers: (handler: (prevState: User[]) => User[]) => void }> = ({setUsers}) => {
    const [username, setUsername] = useState<string>('');
    const [alreadyExists, setAlreadyExists] = useState(false)
    const addUser = () => {
        fetch(URL_BASE + 'api/front/users/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: username}),
        })
            .then((res) => {
                if (res.status === 409) {
                    setAlreadyExists(true)
                    throw new Error("user already exists")
                }
                return res.json();
            })
            .then((data: User) => {
                setUsers((prevState) => [data, ...prevState]);
                setUsername('');
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            addUser();
        }
    };

    return (
        <div className="my-4 mx-2">
            <div className="flex items-center space-x-2">
                <input
                    type="text"
                    value={username}
                    onChange={(e) => {
                        setAlreadyExists(false)
                        setUsername(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    className={`border p-2 rounded-md ${alreadyExists && "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-red-100 dark:border-red-400"}`}
                    placeholder="Enter username"
                />
                <button
                    onClick={addUser}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                    Add User
                </button>
            </div>
        </div>
    );
};