import React, {FC, useCallback, useState} from "react";
import {URL_BASE} from "../urls";

export const ReviewModal: FC<{ onClose: () => void, messageId?: string, userId?:string }> = ({onClose, messageId,userId}) => {
    const [rangeScore, setRangeScore] = useState(.5)
    const [perfect, setPerfect] = useState("")
    const [comment, setComment] = useState("")
    const onSubmit = useCallback(() => {
        fetch(URL_BASE + `api/front/review/${messageId}/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "numOpinion": rangeScore,
                "suggestion": perfect,
                "comment": comment,
                "userId": userId
            })
        }).catch(e => console.error(e))
        onClose()
    }, [rangeScore, perfect, comment, messageId, onClose,userId]);
    const modalTitle = "Leave review"
    return (
        <>
            {(
                <>
                    <div
                        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="fixed left-0 w-auto my-6 mx-auto max-w-3xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-300 outline-none focus:outline-none">
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                    <h3 className="text-black text-2xl">{modalTitle}</h3>
                                    <button
                                        className="bg-transparent border-0 text-black float-right"
                                        onClick={() => onClose()}
                                    >
                    <span
                        className="text-black opacity-7 h-6 w-6 text-xl bg-gray-400 py-0 rounded-full flex items-center  justify-center">
                      &times;
                    </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full">
                                        <label className="block text-black text-sm font-bold mb-1">
                                            Overall score
                                        </label>
                                        <div>
                                            <span className="text-black p-2">
                                                bad
                                            </span>
                                            <input type="range" value={rangeScore}
                                                   onChange={(e) => setRangeScore(+e.target.value)}
                                                   max={1}
                                                   min={0}
                                                   step={0.01}/>
                                            <span className="text-black p-2">
                                                good
                                            </span>
                                        </div>
                                        <label className="block text-black text-sm font-bold mb-1">
                                            How would perfect answer look like
                                        </label>
                                        <textarea value={perfect} onChange={e => setPerfect(e.target.value)}
                                                  className="shadow appearance-none border rounded w-full py-2 px-1 text-black"/>
                                        <label className="block text-black text-sm font-bold mb-1">
                                            Additional comment
                                        </label>
                                        <textarea value={comment} onChange={e => setComment(e.target.value)}
                                                  className="shadow appearance-none border rounded w-full py-2 px-1 text-black"/>
                                    </form>
                                </div>
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={() => onClose()}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="text-white bg-yellow-500 active:bg-yellow-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        onClick={onSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
