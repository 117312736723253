import React, {FC} from "react";

export const Layout: FC<{
    // lang: "pol" | "eng",
    children?: any,
    loginDiv?: any,
    // handleLanguageChange: (selected: "pol" | "eng") => void
}> = ({children,loginDiv}) =>
    <div className="container mx-auto shadow-lg rounded-lg">
        <div className="px-5 py-5 flex justify-between items-center bg-white border-b-2">
            <div className="font-semibold text-2xl">Awatary Live Chat</div>
            {loginDiv}

        </div>
        {children}
    </div>;