import React, {FC, useState} from "react";

export const MessageInput: FC<{ onMessageSent: (newMessage: string) => void }> = ({onMessageSent}) => {
    const [inputValue, setInputValue] = useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (inputValue !== ''){
            onMessageSent(inputValue)
            setInputValue('');
        }
    };

    return (
        <div className="py-5">
            <form onSubmit={handleSubmit}>
                <input
                    className="w-full bg-gray-300 py-5 px-3 rounded-xl"
                    type="text"
                    placeholder="Type your message here..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </form>
        </div>
    );
}